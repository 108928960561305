import React from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";

import MenuImage from "../../images/kg-menu-jan24.JPG";
import DrinksMenu from "../../images/kg-drinks-jan24.JPG";
import "../../style/view-menu.css";

function ViewMenu() {
  return (
    <div>
      <h2>Call us</h2>
      <h2>お問い合わせ</h2>
      <a href='tel:6493078080 '>
        <h3> +09-307-8080</h3>
        <PhoneEnabledIcon fontSize='large' color='action' />
      </a>
      <p> Menu (Updated Jan 2025)</p>
      <div className="menu-image">
      
        <img className="menu-img" src={MenuImage} alt='menu'/>
        <img className="menu-img" src={DrinksMenu} alt="drinks-menu"/>
      </div>
      <p>*Prices are subjected to change at any moment.</p>
    </div>
  );
}

export default ViewMenu;
