import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component='footer'
      sx={{
        mt: 12,
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth='lg'>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h6' color='text.primary' gutterBottom>
              About Us
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              We are KG, please follow us! Stay Safe
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h6' color='text.primary' gutterBottom>
              Contact Us
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              211 Parnell Road, Parnell, Auckland 1052
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              Email: kg_jp_0807@outlook.com
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              <a href="tel:+093078080">Phone 09 307 8080</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h6' color='text.primary' gutterBottom>
              Follow Us
            </Typography>
            <Link href='https://www.facebook.com/' color='inherit'>
              <Facebook />
            </Link>
            <Link
              href='https://www.instagram.com/kg_jp_restaurant/'
              color='inherit'
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href='https://www.twitter.com/' color='inherit'>
              <Twitter />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant='body2' color='text.secondary' align='center'>
            {"Copyright © "}
            <Link color='inherit' href='https://your-website.com/'>
              kg.co.nz
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
